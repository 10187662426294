import React, { useState, useRef, useEffect } from 'react'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import Duration from './components/Duration';
import axios from 'axios';
import { FaPlay } from 'react-icons/fa';
import { isIOS } from "react-device-detect";
import VolumeIcon from "../../images/Icons/volume.png"

import Slider from '@material-ui/core/Slider';

import * as generalRangeStyle from './styles/generalRangeStyles.module.scss'
import * as generalPlayerStyle from './styles/generalPlayerStyles.module.scss'
import * as progressRangeStyle from './styles/progressRangeStyles.module.scss'
import * as volumeRangeStyle from './styles/volumeRangeStyles.module.scss'

import { BsPlayFill, BsPause, BsArrowsFullscreen, BsFillVolumeUpFill, BsFillVolumeMuteFill } from 'react-icons/bs';

import { withStyles, makeStyles } from '@material-ui/core/styles';


const VolumeSlider = withStyles({
    root: {
        color: '#fff',
        height: 3,
        padding: '13px 0',
    },
    thumb: {
        height: 13,
        width: 13,
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            backgroundColor: '#fff',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    track: {
        height: 3,
    },
    rail: {
        color: '#fff',
        opacity: 1,
        height: 3,
    },
})(Slider);


const VideoPlayerComponent = (props) => {

    const [url, setUrl] = useState(props.url)
    const [playing, setPlaying] = useState(false)
    const [volume, setVolume] = useState(0.8)
    const [muted, setMuted] = useState(true)
    const [played, setPlayed] = useState(0)
    const [loaded, setLoaded] = useState(0)
    const [playedSeconds, setPlayedSeconds] = useState(0)
    const [loadedSeconds, setLoadedSeconds] = useState(0)
    const [duration, setDuration] = useState(0)
    const [seeking, setSeeking] = useState(null)
    const [showVolumeRange, setShowVolumeRange] = useState(false)
    const [wrapperHeight, setWrapperHeight] = useState(100)
    const [initiallyPlayed, setInitiallyPlayed] = useState(false)
    const playerContainer = useRef(null)
    const refWrapper = useRef(null)
    const ref = React.createRef()
    const [fullScreenMode, setFullScreenMode] = useState(false)
    const [screenSize, setScreenSize] = useState("desktop");


    const roundWatchedPercentage = (num) => {
        return Math.round(num * 100) / 100
    }

    const makeVideoProgressObject = () => {

        let seconds = localStorage.getItem('secondsPlayed');
        let viewed = localStorage.getItem('percentagePlayed');

        if (seconds == null) return {
            id: -1,
            viewed: 0,
            seconds: 0,
            watched: false
        };

        console.log("secondsPlayed",);

        seconds = parseInt(seconds);
        viewed = (100 * viewed);

        let watched = false;
        viewed = roundWatchedPercentage(viewed);
        let id = JSON.parse(props.id);
        if (viewed > 95.00)
            watched = true
        return {
            id,
            viewed,
            seconds,
            watched
        };
    }

    const checkWindowWidth = () => {
        if (window.innerWidth < 1320) {
            console.log("mobile")
            setScreenSize("mobile")
            return
        }
        console.log("desktop")
        setScreenSize("desktop")
    };


    const modifyProgressArray = (progressArray, progress) => {
        const predicate = (element) => element.id == progress.id;
        const progressArrayContainsProgressId = progressArray.some(predicate);
        if (progressArrayContainsProgressId) {
            let currentVideoObject = progressArray.find(predicate);
            let index = progressArray.findIndex(predicate);
            currentVideoObject.viewed = progress.viewed;
            currentVideoObject.seconds = progress.seconds;
            currentVideoObject.watched = progress.watched;
            progressArray[index] = currentVideoObject;
            return progressArray;
        }
        progressArray.push(progress);
        return progressArray;
    }

    const sendDataBeforeClosing = (ev) => {
        setPlaying(false);
        let token = localStorage.getItem('user');
        let progress = makeVideoProgressObject();

        let progressJson = localStorage.getItem('videoProgress');

        if(progressJson == null){
            return false;
        }

        let progressArray = JSON.parse();
        progressArray = modifyProgressArray(progressArray, progress);
        let data = {
            token,
            progress: progressArray,
        };
        localStorage.setItem('videoProgress', JSON.stringify(progressArray));
        axios.post('https://detoxme.at/wp/wp-json/siegfried/v1/video/progress',
            data
        )
            .then(function (response) {

            })
            .catch(function (error) {

            });
    }

    const onResize = () => {
        checkWindowWidth();
        setTimeout(() => {
            if (refWrapper == null || refWrapper.current == null) {
                return null;
            }
            let height = refWrapper.current.offsetWidth / 1.77777;
            setWrapperHeight(height);
        }, 500);

    };


    useEffect(() => {
        onResize();

        load(props.url);

        if (ref != null && ref.current != null) {
            // console.log(ref.current) 
        }

        if (props.play == true) {
            if(window.innerWidth < 993) {
                setMuted(false);
            } else {
                setMuted(true);
            }
            
            setPlaying(true);
        }



        window.addEventListener("resize", () => {
            onResize();
        })
        window.addEventListener("beforeunload", (ev) => {
            sendDataBeforeClosing(ev);
        })
        return (ev) => {
            sendDataBeforeClosing(ev);
        }
    }, [])


    const handlePlayPause = () => {
        setPlaying(previousState => !previousState)
    }

    const handleVolumeChange = e => {
        if (e > 0) {
            setMuted(false)
        }
        else {
            setMuted(true)
        }

        setVolume(parseFloat(e))
    }

    const handleToggleMuted = () => {
        if (muted) {
            setVolume(1)
        }
        else {
            setVolume(0)
        }
        setMuted(previousState => !previousState)
    }

    const handlePlay = () => {
        setPlaying(true);
    }

    const handlePause = () => {
        setPlaying(false);
    }

    const load = (url) => {
        setUrl(url);
        setPlayed(0);
        setLoaded(0);
    }

    const toggleVideoScreenSize = () => {

        if (isIOS) {
            if (!fullScreenMode) {
                setFullScreenMode(true);
            }
            else {
                setFullScreenMode(false)
            }
        } else {
            if (!fullScreenMode) {
                setTimeout(() => {
                    onResize()
                }, 100);
                console.log("Gesuchter wrapper", refWrapper.current)
                screenfull.request(refWrapper.current);
                setFullScreenMode(true);
            }
            else {
                setTimeout(() => {
                    onResize()
                }, 100);
                screenfull.exit(refWrapper.current)
                setFullScreenMode(false)
            }
        }
    }

    const handleDuration = (duration) => {
        setDuration(duration)
    }

    const handleProgress = ({ played, loaded, loadedSeconds, playedSeconds }) => {
        setPlayed(previousState => played)
        setPlayedSeconds(previousState => playedSeconds)
        setLoaded(previousState => loaded)
        setLoadedSeconds(previousState => loadedSeconds)
        if (props.secondsProgressInLocalStorage) {
            localStorage.setItem('secondsPlayed', playedSeconds);
            localStorage.setItem('percentagePlayed', played);
        }
    }

    if (props.hide !== undefined && props.hide == screenSize && !fullScreenMode) return <></>;


    return (
        <div className='app' style={props.url != '' || props.hide !== false ? { display: "block" } : { display: "none" }}  >


            <section
                style={props.hide === true ? { display: "none" } : { display: "block" }}
                className={generalPlayerStyle.section}
            >

                <div
                    className={generalPlayerStyle.player_wrapper}
                    // id="react-player"
                    style={{ height: wrapperHeight, cursor: "pointer" }}
                    // onMouseOver={() => setShowControls(true)}
                    // onMouseOut={() => setShowControls(false)}
                    ref={refWrapper}
                >

                    {!playing && !initiallyPlayed &&
                        <div style={{ backgroundImage: `url(${props.img})` }} onClick={() => { setPlaying(true); setInitiallyPlayed(true); }} className={generalPlayerStyle.player_start_overlay}>
                            <div className={generalPlayerStyle.player_icon_vertical}>
                                <FaPlay size="100%" color="white" />
                            </div>
                        </div>
                    }
                    <ReactPlayer
                        ref={ref}
                        className={generalPlayerStyle.react_player}
                        width='100%'
                        height='100%'
                        url={url}
                        controls={true}
                        muted={muted}
                        volume={volume}
                        playing={playing}
                        // onPlay={handlePlay}
                        // onPause={handlePause}
                        onProgress={handleProgress}
                        onDuration={handleDuration}
                    />
                    <img className={generalPlayerStyle.volumeButton} src={VolumeIcon} onClick={e => handleToggleMuted()} style={{display: muted ? "static" : "none", height: 80, width: 80, position: 'absolute', top: '40%', left: '50%', transform: "translate(-50%)"}} />
                </div>
            </section>

        </div>
    )


};



export default VideoPlayerComponent;