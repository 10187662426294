// extracted by mini-css-extract-plugin
export var vpPreview = "generalPlayerStyles-module--vp-preview--w0uwT";
export var section = "generalPlayerStyles-module--section--1Jsf-";
export var player_wrapper = "generalPlayerStyles-module--player_wrapper--3Md_g";
export var player_start_overlay = "generalPlayerStyles-module--player_start_overlay--1d3mO";
export var player_icon_vertical = "generalPlayerStyles-module--player_icon_vertical--oFR68";
export var react_player = "generalPlayerStyles-module--react_player--3YiIE";
export var player_overlay = "generalPlayerStyles-module--player_overlay--P9IIw";
export var player_controls = "generalPlayerStyles-module--player_controls--1Sib2";
export var player_control_buttons = "generalPlayerStyles-module--player_control_buttons--1EvxV";
export var playerOverlay = "generalPlayerStyles-module--player-overlay--3kjvp";
export var volumeButton = "generalPlayerStyles-module--volumeButton--1rdaf";